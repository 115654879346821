import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckMark } from '../assets/check.svg';
import { Link } from 'gatsby';

class LanguageSwitcher extends React.Component {

  constructor() {
    super();
    this.state = {
      switchLangOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleClick() {
    if (!this.state.switchLangOpen) {
      window.document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      window.document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      switchLangOpen: !prevState.switchLangOpen,
    }));
  }

  handleOutsideClick() {
    this.handleClick();
  }

  render() {
    const languages = { 'en': 'English', 'de-DE': 'Deutsch' };
    const args = this.props;
    const switchLocaleClassName = this.state.switchLangOpen ? 'switch-active' : 'switch-inactive';
    return (
      <div className={`lang-switcher dropdown ${ switchLocaleClassName }`} onClick={() => this.handleClick()}>
        <a className='header-link language-switcher-dropdown-toggle'>
          <span className=''>{languages[args.locale]}</span>
        </a>
        {this.state.switchLangOpen && <div className='language-switcher-dropdown-menu'>
          <div className='dropdown-item-wrapper language-switcher-dropdown-item-wrapper language-switcher-dropdown-menu-header'>
            Select your language
          </div>
          <div className='dropdown-item-wrapper language-switcher-dropdown-item-selected language-switcher-dropdown-item-wrapper' key={args.locale}>
            <div key={args.locale} className='language-switcher-dropdown-item'>
              <strong>{languages[args.locale]}</strong><CheckMark />
            </div>
          </div>
          {
            args.slugs && args.slugs.map(slug => {
              return (
                <div className='dropdown-item-wrapper language-switcher-dropdown-item-wrapper' key={slug.path}>
                  <div key={slug.locale} className='language-switcher-dropdown-item'>
                    <Link id={slug.locale} to={ slug.path } className='language-switcher-dropdown-toggle-second-level'>
                      <strong>{languages[slug.locale]}</strong>
                    </Link>
                  </div>
                </div>
              );
            })
          }
        </div>
        }
      </div>
    );
  }
}

export default LanguageSwitcher;

import React, { Component } from 'react';
import { ReactComponent as CreativeCommons } from '../assets/creative_commons.svg';
import { Link } from 'gatsby';
import { ReactComponent as CheckMark } from '../assets/check.svg';
import kokoa from '../assets/kokoa.png';
import mathworks from '../assets/MathWorks.png';
import LanguageSwitcher from './LanguageSwitcher.js';

class Footer extends Component {

  constructor() {
    super();
    this.state = {
      switchLangOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleClick() {
    if (!this.state.switchLangOpen) {
      window.document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      window.document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      switchLangOpen: !prevState.switchLangOpen,
    }));
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleClick();
  }
  render(){
    const languages = { 'en': 'English', 'de-DE': 'Deutsch' };
    const args = this.props;
    const course = args.data.courses.edges.find(course => course.node.locale === args.locale);
    const switchLocaleClassName = this.state.switchLangOpen ? 'switch-active' : 'switch-inactive';
    const className = typeof window !== 'undefined' &&
      (window.location.pathname === '/faq' || window.location.pathname === '/faq/') ? 'AEK-footer-margin' : 'AEK-footer';
    return (
      <footer id="AEK-footer" className={ className } ref={node => { this.node = node; }}>
        <LanguageSwitcher slugs={args.slugs} locale={args.locale} />
        <div className='footer-wrap'>
          <div className="footer-inner">
            <img src={kokoa} />
            <section className="logos">
              <div className="cc-symbol">
                <CreativeCommons />
              </div>
              <p>
                { 'Attribution - Non Commercial - Share Alike 3.0 Unported' }
                <br />
                { '(CC BY-NC-SA 3.0)' }
              </p>
            </section>
            <img src={mathworks} />
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

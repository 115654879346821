import PropTypes from 'prop-types';

export const edgeNodesShape = shape => PropTypes.shape({
  edges: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    ...shape,
  }).isRequired).isRequired,
}).isRequired;

export const datoFluidImage = PropTypes.shape({
  fluid: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }).isRequired,
}).isRequired;

import {
  APP_URL,
  HF_ENV,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  DASHBOARD_URL,
} from './env';

const loadHeaderFooter = () => {
  window.auth = new window.arduinoHF.Auth0({
    domain: AUTH0_DOMAIN,
    client_id: AUTH0_CLIENT_ID,
    redirect_uri: APP_URL,
    scope: 'openid profile',
  });

  const headerFooter = new window.arduinoHF.Header(document.getElementById('create-nav'), window.auth, { env: HF_ENV, style: 'create' });
  return headerFooter.init().then(auth0 => {
    if(!auth0.isAuthenticated) {
      document.cookie = 'X-Arduino-Education-AEK=;path=/;domain=.arduino.cc;expires=Thu, 01-Jan-1970 00:00:01 GMT;';
      window.location = DASHBOARD_URL;
    }
    return auth0;
  });
};


export default loadHeaderFooter;

import React, { Component } from 'react';
import { Link } from 'gatsby';
import { DASHBOARD_URL } from '../utils/env';
import lang_eng from '../assets/lang_eng.png';
import lang_ger from '../assets/lang_ger.png';
import lang_swe from '../assets/lang_swe.png';
import Headroom from 'react-headroom';

class Header extends Component {

  constructor() {
    super();
    this.state = {
      switchLangOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleClick() {
    if (!this.state.switchLangOpen) {
      window.document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      window.document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      switchLangOpen: !prevState.switchLangOpen,
    }));
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleClick();
  }

  render() {
    const lang_icons = { it: lang_ger, es: lang_swe, en: lang_eng };
    const args = this.props;
    const course = args.data.courses.edges.find(course => course.node.locale === args.locale);
    return (
      <Headroom style={{ zIndex: 100 }}>
        <header className="topbar" ref={node => { this.node = node; }}>
          <div id="create-nav"></div>
          <div className="topbar-container">
            <Link to="/" className="topbar-logo" />
            <nav className="topbar-menu">
              <ul className="topbar-menu-list">
                <li className="topbar-menu-item">
                  <div className="topbar-menu-dropdown">
                    <a className="topbar-menu-dropdown-toggle">
                      <span className=''>Chapters</span>
                    </a>
                    <div className="topbar-menu-dropdown-fl">
                      {
                        course && course.node.chapters.filter(chapter => chapter.slug !== null)
                          .map((chapter, index) => {
                            const slug_prefix = course.node.locale === 'en' ? '' : `/${ course.node.locale }`;
                            return (
                              <div className='topbar-menu-dropdown-fl-item' key={chapter.id}>
                                <Link to={ `/${ slug_prefix }/chapter/${ chapter.slug }` } className='topbar-menu-dropdown-fl-link' key={chapter.id}>
                                  <div className='topbar-menu-dropdown-fl-link-mono-pre-title'>
                                    Chapter {index+1}
                                  </div>
                                  <div className='topbar-menu-dropdown-fl-link-mono-title'>
                                    {chapter.title}
                                  </div>
                                </Link>
                              </div>
                            );
                          })
                      }
                    </div>
                  </div>
                </li>
                <li className="topbar-menu-item">
                  <a href={DASHBOARD_URL} className="topbar-link">Dashboard</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </Headroom>
    );
  }
}

export default Header;

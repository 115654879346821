import React from 'react';
import { Link } from 'gatsby';
import faqButton from '../assets/faq-button.svg';

const FaqButton = () => {
  return <Link to="/faq" className="faq-button">
    <img src={faqButton} />
  </Link>;
};

export default FaqButton;

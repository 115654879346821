import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { edgeNodesShape } from '../utils/gatsbyPropTypes';

import loadHeaderFooter from '../utils/header-footer';

import Header from './Header';
import Footer from './Footer';
import FaqButton from './FaqButton';
import '../styles/index.sass';

class Layout extends Component {
  componentDidMount = () => {
    loadHeaderFooter();
  }

  render() {
    const { data, slugs, locale } = this.props;
    return (
      <Fragment>
        <Header data={data} slugs={slugs} locale={locale} />
        <HelmetDatoCms
          title="Aek"
          favicon={ data.datoCmsSite.faviconMetaTags }
        />
        <div className="sticky-viewport">
          <FaqButton />
        </div>
        { this.props.children }
        <Footer locale={locale} slugs={slugs} data={data} />
      </Fragment>
    );
  }
}

Layout.propTypes = {
  data: PropTypes.shape({
    datoCmsSite: PropTypes.object,
    slugs: PropTypes.array,
    locale: PropTypes.string,
    courses: edgeNodesShape({
      id: PropTypes.string,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string,
    }).isRequired,
  }),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
};


const LayoutWrapper = ({ slugs, locale, children }) => (
  <StaticQuery query={ graphql`
    query LayoutQuery {
      courses: allDatoCmsCourse {
        edges {
          node {
            id
            slug
            title
            locale
            cardDescription
            chapters {
              id
              slug
              title
            }
          }
        }
      }
      datoCmsSite {
        faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
        }
        globalSeo {
          siteName
        }
      }
    }
  ` }
  render={ data => (
    <Layout locale={locale} slugs={slugs} data={ data }>
      { children }
    </Layout>
  ) }
  />
);

LayoutWrapper.propTypes = {
  children: PropTypes.object,
};

export default LayoutWrapper;

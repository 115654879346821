/* eslint-disable */

/**
 * This file exposes the variables passed from GatsbyJS (Webpack in disguise) and read from the current env file (.env)
 */

export const APP_URL = process.env.GATSBY_APP_URL
export const USER_ROLE = process.env.GATSBY_USER_ROLE || 'student'
export const HF_ENV = process.env.GATSBY_HF_ENV
export const AUTH0_DOMAIN = process.env.GATSBY_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = process.env.GATSBY_AUTH0_CLIENT_ID
export const HF_URL = process.env.GATSBY_HF_URL
export const DASHBOARD_URL = process.env.GATSBY_DASHBOARD_URL
